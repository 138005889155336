export const fetchAllEdicts = async (
  token,
  page = 1,
  perPage = 10,
  includeScheduled = false
) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/edicts?page=${page}&per_page=${perPage}`;
    if (includeScheduled) {
      url += "&include_scheduled=true";
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      return {
        data: errorData,
        status: response.status,
      };
    }

    const data = await response.json();
    return {
      data: data,
      status: response.status,
    };
  } catch (error) {
    console.error("Fetch edicts Error:", error);
    return {
      data: { message: "Conexión no disponible" },
      status: 500,
    };
  }
};
