import React, { useState } from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { FaCalendarAlt } from 'react-icons/fa';
import '../styles/derechofijo.css'
import { postLiquidaciones } from '../api/postLiquidaciones';

registerLocale('es', es);

const Liquidaciones = () => {
    const [liquidacionFormData, setLiquidacionFormData] = useState({
        fecha_inicio: '',
        fecha_final: '',
        importe_inicial: '',
        tipo_calculo: '',
        frecuencia_aplicacion: '1',
        interes_anual: ''
    });

    const [cuotaFormData, setCuotaFormData] = useState({
        fecha_inicio_0: '',
        importe_inicial_0: '',
        fecha_inicio_1: '',
        importe_inicial_1: '',
        fecha_inicio_2: '',
        importe_inicial_2: '',
        fecha_inicio_3: '',
        importe_inicial_3: '',
        fecha_final: '',
        tipo_calculo: '',
        tasa_bancaria: '',
        frecuencia_aplicacion: '',
        interes_anual: ''
    });

    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const handleLiquidacionDateChange = (date, fieldName) => {
        setLiquidacionFormData((prevData) => ({
            ...prevData,
            [fieldName]: date,
        }));
    };

    const handleCuotaDateChange = (date, fieldName) => {
        setLiquidacionFormData((prevData) => ({
            ...prevData,
            [fieldName]: date,
        }));
    };

    const handleLiquidacionChange = (e) => {
        const { name, value } = e.target;
        setLiquidacionFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCuotaChange = (e) => {
        const { name, value } = e.target;
        setCuotaFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleReset = (e) => {
        setLiquidacionFormData({
            lugar: '',
            fecha_final: '',
            juicio_n: '',
            caratula: '',
            juzgado: '',
            fecha_inicio: '',
            tasa_justicia: '',
            parte: '',
            total_depositado: '',
            derecho_fijo_5pc: ''
        })
    }

    const handleSubmitLiquidacion = async (e) => {
        e.preventDefault();

        try {
            await postLiquidaciones(liquidacionFormData);
            // Success message if needed
            setModalMessage("Liquidación generada exitosamente");
        } catch (err) {
            console.error('Error generating liquidacion:', err);
            setModalMessage(err.message || 'Error al generar la liquidación');
        } finally {
            setModalVisible(true);
        }
    };

    const handleSubmitCuotas = (e) => {
        e.preventDefault();
        setLiquidacionFormData((prevliquidacionFormData) => ({
            ...prevliquidacionFormData,
            derecho_fijo_5pc: prevliquidacionFormData.tasa_justicia * 0.05,
        }));
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setModalMessage("");
    };


    return (
        <div className="bg-gray-100 min-h-screen">
            <header className="relative 2xl:h-[70vh] md:h-[80vh] bg-primary bg-cover bg-center flex flex-col justify-center items-center text-white text-center">
                <div className="absolute inset-0 opacity-60 z-0" style={{ backgroundColor: '#06092E' }}></div>

                <NavBar />

                <div className="absolute inset-0 flex flex-col justify-center items-center text-white z-10 px-4">
                    <h5 className="2xl:text-2xl md:text-xl font-normal mb-2" style={{ lineHeight: '1.5' }}>
                        Liquidaciones
                    </h5>
                    <h1 className="2xl:text-7xl md:text-5xl font-normal mb-6" style={{ lineHeight: '1.5' }}>
                        Formularios
                    </h1>
                </div>
            </header>

            {/* The Form Section */}
            <section className="relative z-20 -mt-36 flex justify-center pb-20">
                {/* Left Form - Liquidaciones */}
                <div className="bg-white py-10 px-16 rounded-lg shadow-lg w-1/2">
                    <h2 className="text-center text-2xl font-bold text-primary mb-8">Liquidaciones</h2>
                    <form onSubmit={handleSubmitLiquidacion} onReset={handleReset}>
                        <div className="grid grid-cols-1 gap-4 mb-4">
                            {/* Fecha Inicio */}
                            <div>
                                <label className="block font-semibold text-gray-700 font-bakersville">Fecha inicio</label>
                                <div className="relative w-full">
                                    <DatePicker
                                        selected={liquidacionFormData.fecha_inicio}
                                        onChange={(date) => handleLiquidacionDateChange(date, 'fecha_inicio')}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        placeholderText="dd/mm/yyyy"
                                        className="w-full pl-10 border-b border-gray-300 p-3 focus:outline-none placeholder-gray-500 font-lato"
                                        required
                                    />
                                    <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2" />
                                </div>
                            </div>

                            {/* Fecha Final */}
                            <div>
                                <label className="block font-semibold text-gray-700 font-bakersville">Fecha final</label>
                                <div className="relative w-full">
                                    <DatePicker
                                        selected={liquidacionFormData.fecha_final}
                                        onChange={(date) => handleLiquidacionDateChange(date, 'fecha_final')}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        placeholderText="dd/mm/yyyy"
                                        className="w-full pl-10 border-b border-gray-300 p-3 focus:outline-none placeholder-gray-500 font-lato"
                                        required
                                    />
                                    <FaCalendarAlt className="absolute left-3 top-1/2 transform -translate-y-1/2" />
                                </div>
                            </div>

                            {/* Importe Inicial */}
                            <div>
                                <label className="block font-semibold text-gray-700 font-bakersville">Importe inicial</label>
                                <div className="flex items-center border-b border-gray-300">
                                    <span className="text-gray-500 px-2">$</span>
                                    <input
                                        type="number"
                                        name="importe_inicial"
                                        placeholder="0"
                                        className="w-full p-3 focus:outline-none placeholder-gray-500 font-lato"
                                        value={liquidacionFormData.importe_inicial}
                                        onChange={handleLiquidacionChange}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Select Tipo de Cálculo */}
                            <div>
                                <label className="block font-semibold text-gray-700 font-bakersville">Seleccione tipo de Cálculo</label>
                                <div className="flex flex-col mt-2 space-y-4">

                                    {/* Tasa Bancaria Radio Button */}
                                    <label className="flex items-center text-primary font-lato">
                                        <input
                                            type="radio"
                                            name="tipo_calculo"
                                            value="tasa_bancaria"
                                            checked={liquidacionFormData.tipo_calculo === "tasa_bancaria"}
                                            onChange={handleLiquidacionChange}
                                            className="mr-2"
                                        />
                                        Tasa Bancaria
                                    </label>
                                    {liquidacionFormData.tipo_calculo === "tasa_bancaria" && (
                                        <div className="flex items-center space-x-4 mt-2 ms-4">
                                            <select
                                                name="frecuencia_aplicacion"
                                                className="border-b border-gray-300 p-2 focus:outline-none font-lato"
                                                onChange={handleLiquidacionChange}
                                                value={liquidacionFormData.frecuencia_aplicacion || ""}
                                            >
                                                <option value="1">Aplicar 1 vez</option>
                                                <option value="1.5">Aplicar 1 y 1/2 vez</option>
                                                <option value="2">Aplicar 2 veces</option>
                                            </select>
                                        </div>
                                    )}

                                    {/* Porcentaje de interés anual Radio Button */}
                                    <label className="flex items-center text-primary font-lato">
                                        <input
                                            type="radio"
                                            name="tipo_calculo"
                                            value="interes_anual"
                                            checked={liquidacionFormData.tipo_calculo === "interes_anual"}
                                            onChange={handleLiquidacionChange}
                                            className="mr-2"
                                        />
                                        Porcentaje de interés anual
                                    </label>
                                    {liquidacionFormData.tipo_calculo === "interes_anual" && (
                                        <div className="flex items-center space-x-4 mt-2">
                                            <input
                                                type="number"
                                                name="interes_anual"
                                                placeholder="0.00"
                                                className="w-full border-b border-gray-300 p-2 focus:outline-none font-lato"
                                                value={liquidacionFormData.interes_anual || ""}
                                                onChange={handleLiquidacionChange}
                                            />
                                            <span className="text-gray-500">% Anual</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Button Section */}
                            <div className="flex justify-end space-x-4 font-lato mt-6">
                                <button type="reset" className="px-4 py-2 border-gray-200 border-2 text-gray-700 rounded-lg">
                                    Reiniciar
                                </button>
                                <button type="submit" className="px-4 py-2 bg-secondary text-white rounded-lg">
                                    Calcular
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <section className="mt-9 pb-24">
                <div className="container mx-auto text-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-center items-center">
                        {/* Otras herramientas */}
                        <div className="flex flex-col items-center">
                            <h3 className="text-2xl font-semibold mb-4 text-primary">Otras herramientas</h3>
                            <div className="grid grid-cols-2 gap-8">
                                <a href="/derecho-fijo" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary text-left">
                                    Derecho Fijo
                                </a>
                                <a href="#caja-forense" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary text-left">
                                    Caja forense
                                </a>
                                <a href="#edictos" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary text-left">
                                    Edictos
                                </a>
                            </div>
                        </div>

                        {/* Links de interés */}
                        <div className="flex flex-col items-center">
                            <h3 className="text-2xl font-semibold mb-4 text-primary">Links de interés</h3>
                            <div className="grid grid-cols-2 gap-8">
                                <a href="#poder-judicial-mza" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary">
                                    Poder judicial Mza
                                </a>
                                <a href="#listas-diarias" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary">
                                    Listas diarias
                                </a>
                                <a href="#notificaciones" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary">
                                    Notificaciones
                                </a>
                                <a href="#atm" className="font-lato text-base text-gray-700 hover:text-primary border-b border-gray-300 hover:border-primary">
                                    ATM
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {modalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 z-50">
                    <div className="bg-white p-6 rounded shadow-lg w-80 text-center">
                        <span>{modalMessage}</span>
                        <button onClick={handleCloseModal} className="mt-4 bg-indigo-500 text-white px-4 py-2 rounded font-lato">
                            Cerrar
                        </button>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    )
}

export default Liquidaciones